//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SectionBillboardLink",
  components: {
    Button: () => import('@/components/common/Button')
  },
  props: {
    link: {
      type: String,
      default: () => ''
    },
    title: {
      type: String,
      default: () => ''
    },
    background: {
      type: String,
      default: () => 'var(--main-color)'
    },
    color: {
      type: String,
      default: () => 'var(--dark-color)'
    }
  }
}
